import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withNamespaces } from "react-i18next"
import BannerUnsuscribe from "../components/unsuscribe/BannerUnsuscribe"

const Unsuscribe = () => {
  return (
    <Layout>
      <SEO
        title={"Unsuscribe | Exsis Digital Angels"}
        description={"Nearshore Agile Teams | Specialists in Tech Talent"}
      />
      <BannerUnsuscribe />
    </Layout>
  )
}

export default withNamespaces()(Unsuscribe)
